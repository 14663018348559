import {css, cx} from "@linaria/core";
import {CenterBox} from "@web2/ui_utils";
import {useUserDevice} from "@web2/user-device";

import {currentStaticsDir} from "../../app/constants/current_statics_dir";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";

const placeholder360x337 = `${ghCommonUrl}/${currentStaticsDir}/konkurs_360x337.png`;
const placeholder1239x242 = `${ghCommonUrl}/${currentStaticsDir}/konkurs_1239x242.png`;

interface Props {
    className?: string;
    fetchPriority?: "high";
}

export const AdLoader = (props: Props) => {
    const {isMobile} = useUserDevice();
    return (
        <CenterBox className={cx(box, props.className)}>
            {isMobile ? (
                <img
                    src={placeholder360x337}
                    alt="placement placeholder"
                    width={360}
                    height={337}
                    className={adLoaderPlaceholder}
                    fetchpriority={props.fetchPriority}
                />
            ) : (
                <img
                    src={placeholder1239x242}
                    alt="placement placeholder"
                    width={1239}
                    height={242}
                    className={adLoaderPlaceholder}
                    fetchpriority={props.fetchPriority}
                />
            )}
        </CenterBox>
    );
};

const adLoaderPlaceholder = css`
    display: block;
    max-width: 100%;
    height: auto;
`;

const box = css`
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
`;
