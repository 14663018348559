import * as React from "react";
import {useRef, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {map, omit} from "lodash";
import {Button} from "@web2/button";
import {Popover} from "@web2/floating";
import {SORT_BUTTON_TESTID} from "@web2/gh_page_object_models/offer_list/test_ids";
import {useClickOutside} from "@web2/react_utils";
import {nbsp} from "@web2/string_utils";

import {useTransitioningHistoryPush} from "../../../app/hooks/use_transitioning_history_push";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {parseSearch} from "../../../app/utils/request_response_utils/parse_search";
import {dropdownList, dropdownListElement} from "../../../search/components/atoms/atoms";
import {DropdownListElementBody} from "../../../search/components/atoms/DropdownListElementBody";
import {getThemeBreakpointCorrect, getThemeVariable} from "../../../styles/linaria_variable_factory";
import {gtmOfferListSortEvent} from "../../../tracking/google_tag_manager/gtm_offer_list_events";
import {ArrowDownIcon} from "./icons/ArrowDownIcon";
import {ArrowUpIcon} from "./icons/ArrowUpIcon";
import {SortIcon} from "./icons/SortIcon";
import {MAPVIEW_PARAM} from "./OfferListDynamic";

export enum SortType {
    DEFAULT = "",
    PRICE_ASC = "price",
    PRICE_DESC = "-price",
    PRICE_PER_SQM_ASC = "price_per_sqm",
    PRICE_PER_SQM_DESC = "-price_per_sqm",
    NEWEST = "-created",
    SIZE_ASC = "size",
    SIZE_DESC = "-size"
}

const sortOptions = [
    {
        optionName: `Wybrane dla${nbsp}Ciebie`,
        label: "Sortuj",
        optionNameShort: "Sortuj",
        value: SortType.DEFAULT
    },
    {
        optionName: "Najnowsze",
        value: SortType.NEWEST
    },
    {
        optionName: "Najtańsze",
        value: SortType.PRICE_ASC
    },
    {
        optionName: "Najdroższe",
        value: SortType.PRICE_DESC
    },
    {
        optionName: "Najtańsze za m2",
        optionNameShort: "Najtańsze/m2",
        value: SortType.PRICE_PER_SQM_ASC
    },
    {
        optionName: "Najdroższe za m2",
        optionNameShort: "Najdroższe/m2",
        value: SortType.PRICE_PER_SQM_DESC
    },
    {
        optionName: "Powierzchnia (rosnąco)",
        optionNameShort: "Najmniejsze",
        value: SortType.SIZE_ASC
    },
    {
        optionName: "Powierzchnia (malejąco)",
        optionNameShort: "Największe",
        value: SortType.SIZE_DESC
    }
];

const getLabel = (sortType: SortType, useShortOptionNames?: boolean) => {
    const foundOption = sortOptions.find((sO) => sO.value === sortType);
    const optionName = (useShortOptionNames ? foundOption?.optionNameShort : foundOption?.optionName) || foundOption?.optionName;
    return foundOption?.label ?? optionName;
};

const getOptionName = (sortType: SortType, useShortOptionNames?: boolean) => {
    const foundOption = sortOptions.find((sO) => sO.value === sortType);
    const displayName = foundOption && useShortOptionNames ? foundOption.optionNameShort : foundOption?.optionName;
    return displayName || foundOption?.optionName || "Wybrane dla Ciebie";
};
interface IProps {
    latestQuerySort?: SortType;
    className?: string;
    useShortDisplayNames?: boolean;
    fullWidth?: boolean;
    textWrapperClassName?: string;
    disabled?: boolean;
}

export const OfferListSortButton = (props: IProps) => {
    const history = useHistory();
    const location = useLocation();
    const isMobileMapOpen = location.search.includes(MAPVIEW_PARAM);

    const sortWrapperRef = useRef<HTMLElement | null>(null);
    const sortWrapperRefHandler = (elem: HTMLElement | null) => {
        sortWrapperRef.current = elem;
    };
    const [isSortOpen, setSortOpen] = useState(false);
    const {transitionHistory} = useTransitioningHistoryPush();

    useClickOutside(sortWrapperRef, () => {
        setSortOpen(false);
    });

    const onOptionClick = (option: {optionName: string; value: SortType}) => {
        setSortOpen(false);
        const search = parseSearch(history.location.search);
        const url = appendQueryString(location.pathname, {...omit(search, ["sort", "page"]), ...(option.value ? {sort: option.value} : {})});
        // send full option names to GTM
        gtmOfferListSortEvent(getOptionName(option.value));
        transitionHistory(url);
    };

    return (
        <div ref={sortWrapperRefHandler} className={cx(sortHolder, props.className)}>
            <Popover
                disableFocus
                externalOpenState={[isSortOpen, setSortOpen]}
                className={popoverPosition}
                popover={
                    <SortBodyHolder data-testid={SORT_BUTTON_TESTID.SORT_LIST}>
                        <ul className={dropdownList}>
                            {map(sortOptions, (sO, idx) => {
                                if (sO.value === props.latestQuerySort) {
                                    return null;
                                }
                                return (
                                    <li key={idx} className={dropdownListElement}>
                                        <DropdownListElementBody onClick={() => onOptionClick(sO)}>{sO.optionName}</DropdownListElementBody>
                                    </li>
                                );
                            })}
                        </ul>
                    </SortBodyHolder>
                }
            >
                {({refs, getReferenceProps}) => (
                    <div ref={refs.setReference} {...getReferenceProps()}>
                        <OfferListHeaderButton
                            size="sm"
                            variant="primary"
                            inverted
                            fullWidth={props.fullWidth}
                            onClick={() => setSortOpen(!isSortOpen)}
                            icon={<SortIcon />}
                            disabled={isMobileMapOpen || props.disabled}
                            style={{
                                color: getThemeVariable("colors-text_color"),
                                borderColor: getThemeVariable("colors-gray_brighter"),
                                height: "3.8rem",
                                borderRadius: getThemeVariable("other-border_radius"),
                                transition: "background-color 100ms ease-out, border-color 100ms ease-out"
                            }}
                        >
                            <span className={props.textWrapperClassName} data-testid={SORT_BUTTON_TESTID.DROPDOWN_SORT_BUTTON}>
                                {getLabel(props.latestQuerySort ? props.latestQuerySort : SortType.DEFAULT, props.useShortDisplayNames)}
                            </span>
                            {isSortOpen ? <ArrowUpIcon size="1.2" /> : <ArrowDownIcon size="1.2" />}
                        </OfferListHeaderButton>
                    </div>
                )}
            </Popover>
        </div>
    );
};

const sortHolder = css`
    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        width: 100%;
    }
`;

const popoverPosition = css`
    position: relative;
    z-index: 3;
`;

const SortBodyHolder = styled.div`
    padding: 0.5rem;
    background: ${getThemeVariable("colors-body_bg")};
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: ${getThemeVariable("other-border_radius")};
    max-width: 220px;

    &:before {
        width: 1.2rem;
        height: 1.2rem;
        content: "";
        position: absolute;
        top: -0.6rem;
        left: calc(50% - 0.6rem);
        transform: rotate(45deg);
        border-radius: 1px;
        background-color: white;
        z-index: 12;
    }
`;

const OfferListHeaderButton = styled(Button)`
    width: 100%;
    display: flex;

    flex: 0 5 auto;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border-color: ${getThemeVariable("colors-brand_primary")};
        background-color: ${getThemeVariable("colors-filter_active_bg_light")};
    }

    & > span {
        display: grid;
        grid-template-columns: 2rem calc(100% - 4rem) 2rem;
    }

    @media (min-width: ${getThemeBreakpointCorrect().screen_md}) {
        width: 100%;
        min-width: 140px;
        height: 3.4rem !important;
        border-radius: ${getThemeVariable("other-border_radius_sm")};
    }

    span + svg {
        margin-left: 1rem;
        margin-right: 0;
    }
`;
