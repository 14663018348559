import * as React from "react";
import {css} from "@linaria/core";

import {getThemeVariable} from "../../../styles/linaria_variable_factory";
import {GenericListingDescription} from "../generic_listings/GenericListingDescription";
import {useGenericListingDescription} from "../generic_listings/hooks/useGenericListingDescription";
import {wrapper} from "./atoms/atoms";
import {BuildingSvgIcon} from "./icons/BuildingSvgIcon";

interface IOwnProps {
    locationDescription: string;
    shouldRenderSeoDescription: boolean;
}

interface IProps extends IOwnProps {}

export const OfferListSeoDescription = (props: IProps) => {
    const {displayGenericListingCondition} = useGenericListingDescription();

    const shouldRender = props.shouldRenderSeoDescription || displayGenericListingCondition;

    if (shouldRender) {
        return (
            <div className={wrapper}>
                <div className={descriptionHolder}>
                    <div className={sectionTitleHolder}>
                        <BuildingSvgIcon />

                        <div className={sectionTitle}>Poznaj region</div>
                    </div>

                    {props.shouldRenderSeoDescription && <div dangerouslySetInnerHTML={{__html: props.locationDescription}} />}
                    <GenericListingDescription />
                </div>
            </div>
        );
    }
    return null;
};

/**
 * Styles
 */

const descriptionHolder = css`
    margin-top: 2rem;
    padding: 1rem;
    background: ${getThemeVariable("colors-gray_warmer")};
    border: 1px solid ${getThemeVariable("colors-gray_bright")};
    border-radius: 0.5rem;
`;

const sectionTitleHolder = css`
    display: flex;
    align-items: center;
    margin: 2rem 0 2rem 0;
    text-decoration: underline;
    text-decoration-color: ${getThemeVariable("colors-brand_primary")};
`;

const sectionTitle = css`
    font-size: 2.5rem;
    font-weight: 300;
    margin: 0 0 0 1rem;
`;
