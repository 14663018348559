import {useEffect, useRef} from "react";
import {css, cx} from "@linaria/core";
import {styled} from "@linaria/react";
import {usePrevious, useStateRef} from "@web2/react_utils";

import {enableAds, sasPlacementSite} from "../../app/utils/read_environment_variables";
import {sasErrorVariableName} from "../init_sas";
import {calculatePlacementDest, PlacementDest} from "../PlacementDest";
import {getPageId, PlacementPage} from "../PlacementPage";
import {PlacementSiteId} from "../PlacementSiteId";
import {AdLoader} from "./AdLoader";

interface IProps {
    dest: PlacementDest;
    page: PlacementPage;
    placeholder: {
        image: string;
        url: string;
    };
    breakpoint?: number;
    className?: string;
    target?: string;
    fetchPriority?: "high";
}

enum AdStatus {
    LOADING,
    SHOW_PLACEHOLDER,
    SHOW_AD
}

const AD_TIMEOUT_MS = 5000;

const dimensions: Record<PlacementDest, {width: number; height: number}> = {
    desktop: {
        width: 1239,
        height: 242
    },
    mobile: {
        width: 360,
        height: 337
    }
};

export const AdPlacement = ({dest, page, className, placeholder, breakpoint, target = "", fetchPriority}: IProps) => {
    // Turning on/off ads
    if (!enableAds) {
        return null;
    }

    const adDivRef = useRef<HTMLDivElement>(null);
    const [adStatus, setAdStatus, adStatusRef] = useStateRef(AdStatus.LOADING);

    const prevTarget = usePrevious(target, target);
    const isPlacementValid = dest === calculatePlacementDest(breakpoint);

    useEffect(() => {
        if (sasPlacementSite == null) {
            return;
        }

        let initTimeout = 0;
        let adRequestTimeout = 0;
        let initAttempts = 0;

        const initPlacement = () => {
            const siteId = sasPlacementSite === "prod" ? PlacementSiteId.prod : PlacementSiteId.stage;
            initTimeout = window.setTimeout(() => {
                const win = window as any; // eslint-disable-line @typescript-eslint/no-explicit-any

                if (win[sasErrorVariableName]) {
                    // script could have been blocked by AdBlock
                    setAdStatus(AdStatus.SHOW_PLACEHOLDER);
                    return;
                }

                if (win.sas == null || win.sas.cmd == null) {
                    if (++initAttempts > 20) {
                        setAdStatus(AdStatus.SHOW_PLACEHOLDER);
                        return console.warn("Cannot load ads script");
                    }
                    // another attempt
                    initPlacement();
                    return;
                }

                adRequestTimeout = window.setTimeout(() => {
                    // automatically show placeholder if SAS does not respond
                    if (adStatusRef.current === AdStatus.LOADING) {
                        setAdStatus(AdStatus.SHOW_PLACEHOLDER);
                    }
                }, AD_TIMEOUT_MS);

                win.sas.cmd.push(function () {
                    win.sas.call(
                        "std",
                        {
                            siteId, // Site : web-dev-gh.rynekpierwotny.com — stage; gethome.pl — prod
                            pageId: getPageId(siteId, page), // Page : web-dev-gh.rynekpierwotny.com — stage; gethome.pl — prod
                            formatId: 120670, // Format : GH Banner 1239x242
                            target // Targeting
                        },
                        {
                            onNoad: function (noAdRes: {formatId: "120670"; tagId: "sas_120670"}) {
                                // We need to show placeholder only on "noad" event.
                                setAdStatus(AdStatus.SHOW_PLACEHOLDER);
                            },
                            onAd: function (onAdRes: {formatId: "120670"; tagId: "sas_120670"}) {
                                if (adStatusRef.current !== AdStatus.SHOW_PLACEHOLDER) {
                                    clearTimeout(adRequestTimeout);
                                    setAdStatus(AdStatus.SHOW_AD);
                                }
                            }
                        }
                    );
                });
            }, 200);
        };

        if (isPlacementValid) {
            // fetch creative for placement when declared dest (desktop/mobile) corresponds to viewport width
            initPlacement();
        } else if (adDivRef.current) {
            // disable this SAS div for potential ad rendering, SAS id should be unique
            setAdStatus(AdStatus.SHOW_PLACEHOLDER);
            adDivRef.current.id = "invalidated_ad_div_id";
        }

        return () => {
            clearTimeout(adRequestTimeout);
            clearTimeout(initTimeout);
        };
    }, []);

    useEffect(() => {
        const win = window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
        if (win[sasErrorVariableName] || !win.sas?.refresh || target === prevTarget) {
            // script is not ready or there is no reason to update
            return;
        }

        if (isPlacementValid) {
            setAdStatus(AdStatus.LOADING);

            // reload ad placement with new targeting
            win.sas.refresh(120670, {target});
        }
    }, [target]);

    if (sasPlacementSite == null) {
        return null;
    }

    const adDimensions = dimensions[dest];
    const classNames = cx(className, aspectHolder);

    return (
        <div
            className={classNames}
            style={{
                paddingTop: `min(${(adDimensions.height / adDimensions.width) * 100}%, ${adDimensions.height}px)`
            }}
        >
            <div className={innerAspectStyles}>
                {adStatus === AdStatus.LOADING && <AdLoader fetchPriority={fetchPriority} />}

                <AdWrapper ref={adDivRef} id="sas_120670" hide={adStatus === AdStatus.SHOW_PLACEHOLDER} />

                {adStatus === AdStatus.SHOW_PLACEHOLDER && (
                    <AdWrapper>
                        <a href={placeholder.url} target="_blank">
                            <img src={placeholder.image} alt="wygraj 100 tys. zł na mieszkanie!" />
                        </a>
                    </AdWrapper>
                )}
            </div>
        </div>
    );
};

/**
 * CSS
 */

const aspectHolder = css`
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
`;

const innerAspectStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
`;

const AdWrapper = styled.div<{hide?: boolean}>`
    width: 100%;
    display: ${(props) => (props.hide ? "none" : "flex")};
    justify-content: center;
    align-items: center;

    a {
        margin: 0 auto;
        max-width: 100%;

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
`;
