import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const InfoCircleIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path
                d="M5,.85A4.15,4.15,0,1,0,9.14,5,4.14,4.14,0,0,0,5,.85Zm0,9A4.89,4.89,0,1,1,9.89,5,4.89,4.89,0,0,1,5,9.89ZM5,7.28a.37.37,0,0,1-.37-.37V4.29a.37.37,0,1,1,.74,0V6.91A.37.37,0,0,1,5,7.28ZM4.73,3.05a.39.39,0,0,0,.54,0,.4.4,0,0,0,.1-.26.43.43,0,0,0-.1-.27.4.4,0,0,0-.54,0,.43.43,0,0,0-.1.27A.4.4,0,0,0,4.73,3.05Z"
                fill={fillColor}
                fillRule="evenodd"
            />
        </SvgIcon>
    );
};
